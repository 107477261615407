import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill, faAddressCard, faCoins, faFileInvoiceDollar, faRobot, faPrint, faLock, faPlus, faDollarSign, faHouse, faReceipt, faCalendarWeek, faClock, faMobile, faStore } from '@fortawesome/free-solid-svg-icons'


function ServicesTake() {
  return (
    <section className="our-services-container">
      <div className="our-services-title">
        <h2> Nossos benefícios</h2>
        <h1>Por que ter no meu estabelecimento?</h1>
        <div className="divider" id="our-services-divider">

        </div>

      </div>
      <div className="our-services-content">
        <div className="our-services-square" id="insurance">
          <div className="our-services-icon">
            <FontAwesomeIcon icon={faLock} id="service-icon" />
            <h3 className="service-title">Sistema seguro</h3>
          </div>
          <div className="our-services-text">

            <span className="service-description">A inteligência artificial só libera os acessos à geladeira após uma análise no cadastro do usuário. Se ele cumprir todos os requisitos como idade, cartão de crédito cadastrado,  pagamentos processados em dia, ele será liberado para o uso. Caso contrário, a porta do cooler não abrirá. Além disso, a IA garante a cobrança correta da quantidade e rótulo dos produtos retirados
            </span>
          </div>
        </div>
        <div className="our-services-square" id="bank-financial">
          <div className="our-services-icon">
            <FontAwesomeIcon icon={faReceipt} id="service-icon" />
            <h3 className="service-title">Controle em tempo real</h3>
          </div>
          <div className="our-services-text">

            <span className="service-description">O cooler oferece relatórios detalhados de uso em tempo real. Ou seja, sabemos de todas as movimentações realizadas na geladeira. Isso nos dá um panorama geral de consumo, quais rótulos ainda estão disponíveis, se está em pleno funcionamento, se há alguma instabilidade ou mau uso. Com isso, somos mais assertivos na velocidade de reposição das bebidas e também na resolução de algum possível problema.</span>
          </div>
        </div>
        <div className="our-services-square" id="civil-litigation">
          <div className="our-services-icon">
            <FontAwesomeIcon icon={faCalendarWeek} id="service-icon" />
            <h3 className="service-title">
              Atendimento semanal</h3>
          </div>
          <div className="our-services-text">

            <span className="service-description">A visita no ponto de venda é feita semanalmente, independente de necessitar repor bebidas ou não. O objetivo é acompanhar de perto a limpeza, a conectividade e o suporte ao cliente. Isso garante uma melhor experiência e disponibilidade de bebidas para os moradores.</span>
          </div>
        </div>
        <div className="our-services-square" id="family-law">
          <div className="our-services-icon">
            <FontAwesomeIcon icon={faClock} id="service-icon" />
            <h3 className="service-title">Funcionamento full time</h3>
          </div>
          <div className="our-services-text">

            <span className="service-description">A geladeira permanece disponível para uso 24 horas por dia, 7 dias por semana. Ou seja, é uma conveniência a qualquer momento para garantir um refresco, um brinde ou uma hidratação extra!</span>
          </div>
        </div>
        <div className="our-services-square" id="business-law">
          <div className="our-services-icon">
            <FontAwesomeIcon icon={faMobile} id="service-icon" />
            <h3 className="service-title">
              Transações somente pelo aplicativo</h3>
          </div>

          <div className="our-services-text">

            <span className="service-description">As transações de pagamento são realizadas somente pelo aplicativo, sem haver possibilidade de uso de maquininha de cartão. No aplicativo é possível cadastrar mais de uma opção de cartão de crédito (inclusive os virtuais), além de poder realizar um pix copia e cola para deixar um saldo na carteira do app, assim como no Uber. Essa exclusividade de transação é mais uma camada de segurança para garantir o bom uso e tranquilidade de todos</span>
          </div>
        </div>
        <div className="our-services-square" id="criminal-law">
          <div className="our-services-icon">
            <FontAwesomeIcon icon={faStore} id="service-icon" />
            <h3 className="service-title">
              Não há vínculo com o estabelecimento </h3>
          </div>
          <div className="our-services-text">

            <span className="service-description">Qualquer tipo de possível fraude no uso da geladeira, não envolverá o estabelecimento para ressarcimento de valores, ou qualquer outro tipo de inconveniência. Essa parte burocrática é resolvida de forma direta entre a Take e o usuário final.</span>
          </div>
        </div>
      </div>

    </section>
  );
}

export default ServicesTake;
