

import '../../providers/Lumi.css'
import { Element } from 'react-scroll'
import Marcas from "./Marcas";
import CapaLumi from "./Capa-Lumi";
import SobreLumi from "./SobreLumi";
import ServicesLumi from './ServicesLumi';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
function LumiLight() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <>


            <CapaLumi image={'take'} />
            <Element id='solucoes' name='solucoes'>
                <Marcas ></Marcas>
            </Element>
            <Element id='sobre' name='sobre'>
                <SobreLumi />
            </Element>
            <Element id='sobre' name='sobre'>
                <ServicesLumi />
            </Element>
            {/* <Services /> */}

            {/* <Element id='testimonios' name='testimonios'>

                <Testimonials style={{ border: '2px solid red' }} />
            </Element>
            <Element id='contato' name='contato'>

                <Contate style={{ border: '2px solid red' }} />
            </Element> */}

        </>
    );
}

export default LumiLight;
