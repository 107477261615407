import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clipproVideo from '../../assets/clippro-video.gif'
import { faMoneyBill, faAddressCard, faCoins, faFileInvoiceDollar, faRobot, faPrint, faLock, faPlus, faDollarSign, faHouse, faChartSimple, faBold, faBolt, faRegistered, faMoneyBillTransfer, faBoxesStacked, faChartLine, faUtensils, faShield, faMobile, faMobileScreenButton } from '@fortawesome/free-solid-svg-icons'


function DetialsClip() {
  return (
    <section className="zuc-details">
      <div className="zuc-details-container">
        <div className="zuc-details-content">
          <div className='zuc-images'>
            <img src={require("../../assets/clip-cadastro.png")} className='zuc-details-image ' />
          </div>
          <div className="zuc-details-text">
            <div className="zuc-details-title">
              <FontAwesomeIcon icon={faAddressCard} id="service-icon-zuc" />
              <span>Cadastro</span>
            </div>
            <h1>

              Todos os seus cadastros disponíveis em um Clipp
            </h1>
            <p >
              Em um click, o sistema traz para você os cadastros de tudo na empresa. Não deixe nenhum detalhe passar despercebido e comece a evoluir o seu planejamento. Do cadastro de fornecedores à saída de mercadorias, tudo fica registrado em um único sistema.
            </p>
            <h3>
              Produtos e Serviços
            </h3>
            <span>
              Com tudo registrado no sistema, você agiliza as vendas e garante bons resultados.
            </span>
            <h3>
              Gestão Completa
            </h3>
            <span>
              Com todos os cadastros no Clipp, as informações ficam mais fáceis e sempre à mão          </span>
            <button id='btnCenter'>Quero Saber Mais</button>


          </div>



        </div>

      </div>
      <div className="zuc-details-container" id='whiteBg'>
        <div className="zuc-details-content" id='j-column'>


          <div className="zuc-details-text" id='alignStart'>
            <div className="zuc-details-title">
              <FontAwesomeIcon icon={faMoneyBillTransfer} id="service-icon-zuc" />
              <span>Financeiro</span>
            </div>
            <h1>
              Ganhe tempo e otimize seu financeiro com o Clipp
            </h1>
            <p >
              Faça a gestão financeira completa da sua empresa usando uma ferramenta amigável e fácil de usar. Você consegue acompanhar o movimento do caixa em um uma única tela, que mostra também os produtos mais vendidos e os resultados da equipe de vendas.            </p>
            <h3>
              Boletos, Notas e Carnês            </h3>
            <span>
              Tenha em mãos todas as formas de cobrança e facilite a gestão de entradas e saídas.            </span>
            <h3>
              Controle de Comissões            </h3>
            <span>
              Acompanhe a produtividade da equipe de vendas com cálculo automático de comissões.       </span>

            <button>Quero Saber Mais</button>

          </div>
          <div className='zuc-images'>
            <img src={require("../../assets/clip-financeiro-bg.png")} className='zuc-details-image ' />

            <img src={require("../../assets/clip-financeiro.png")} className='zuc-details-image ' />

          </div>

        </div>

      </div>
      <div className="zuc-details-container">
        <div className="zuc-details-content">
          <div className='zuc-images'>
            <img src={require("../../assets/clip-financeiro-2-bg.png")} className='zuc-details-image ' />

            <img src={require("../../assets/clip-financeiro-2.png")} className='zuc-details-image ' />

          </div>
          <div className="zuc-details-text">
            <div className="zuc-details-title">
              <FontAwesomeIcon icon={faFileInvoiceDollar} id="service-icon-zuc" />
              <span>Fiscal</span>
            </div>
            <h1>


              Depois do Clipp, ficar em dia com o fiscal é fácil, fácil.
            </h1>
            <p >
              Da parametrização de tributos à otimização de tarefas diárias. Encontre tudo num click e veja como até sua vida fiscal melhora com o Clipp. Emita NFe, NFCe, SAT, MFe, CTe e CTe OS. Tudo de forma fácil, rápida e segura, atendendo a legislação do seu estado.            </p>
            <h3>
              Painel do Contador            </h3>
            <span>
              Compartilhe arquivos fiscais com a contabilidade de forma mais ágil, rápida e segura            </span>
            <h3>
              Sem Necessidade de Backup            </h3>
            <span>
              O sistema armazena seus documentos fiscais em nuvem, para que nada seja perdido.         </span>
            <button id='btnCenter'>Quero Saber Mais</button>


          </div>



        </div>

      </div>
      <div className="zuc-details-container" id='whiteBg'>
        <div className="zuc-details-content" id='j-column'>


          <div className="zuc-details-text" id='alignStart'>
            <div className="zuc-details-title">
              <FontAwesomeIcon icon={faBoxesStacked} id="service-icon-zuc" />
              <span>Estoque</span>
            </div>
            <h1>

              Depois do Clipp, seu estoque nunca mais será o mesmo. E isso é ótimo!
            </h1>
            <p >
              Quem usa o Clipp percebe a diferença entre um estoque organizado e funcional de verdade. Não perca mais vendas ou serviços por falta de material, otimize os processos com funções específicas para cada tipo de negócio, tanto no varejo quanto no atacado.
            </p>
            <h3>
              Seu Estoque em um Clipp            </h3>
            <span>
              Tenha sempre à mão todas as informações do estoque com interface amigável.           </span>
            <h3>
              Funções Específicas para seu Negócio

            </h3>
            <span>
              Preço promocional, kit de produtos, código de barras e controle por serial, lote e grade.       </span>

            <button>Quero Saber Mais</button>

          </div>
          <div className='zuc-images'>
            <img src={require("../../assets/clip-estoque-bg.png")} className='zuc-details-image ' />

            <img src={require("../../assets/clip-estoque.png")} className='zuc-details-image ' />

          </div>

        </div>

      </div>
      <div className="zuc-details-container">
        <div className="zuc-details-content">
          <div className='zuc-images'>

            <img src={require("../../assets/clip-relatorio.png")} className='zuc-details-image ' />

          </div>
          <div className="zuc-details-text">
            <div className="zuc-details-title">
              <FontAwesomeIcon icon={faChartLine} id="service-icon-zuc" />
              <span>Relatórios</span>
            </div>
            <div>

            </div>
            <h1>
              Chega de se perder em relatórios! Organize tudo num Clipp
            </h1>
            <p >
              No Clipp você pode escolher entre diversas opções de relatórios para ter sempre à mão dados precisos e fáceis de entender. Afinal, uma boa gestão não se faz só pela quantidade de relatórios, mas também pela qualidade e entendimento das informações.

            </p>
            <h3>
              Dashboard Personalizável
            </h3>

            <span>
              Tela para acompanhamento dos dados comerciais, estoque, vendas e pagamentos.
            </span>
            <h3>
              Do Estoque às Vendas
            </h3>
            <span>
              Gere relatórios de compras, vendas, comissões e muito, mas muito mais!         </span>
            <button id='btnCenter'>Quero Saber Mais</button>


          </div>



        </div>

      </div>
      <div className="zuc-details-container" id='whiteBg'>
        <div className="zuc-details-content" id='j-column'>


          <div className="zuc-details-text" id='alignStart'>
            <div className="zuc-details-title">
              <FontAwesomeIcon icon={faShield} id="service-icon-zuc" />
              <span>Serviços</span>
            </div>
            <h1>

              O Clipp também é perfeito pra quem presta serviços
            </h1>
            <p >
              Tenha um acesso personalizado para cada colaborador, controle total de documentos eletrônicos, gestão de estoque pra não perder serviço por falta de peças e muito mais. Você pode resolver tudo da gestão dos seus serviços em um click.            </p>
            <h3>
              Gestão de Contratos           </h3>
            <span>
              Gerencie pedidos e entregas de forma fácil, melhorando o atendimento aos clientes.          </span>
            <h3>
              Módulo de Locações
            </h3>
            <span>
              Gerencie os equipamentos que foram locados para outras empresas.</span>
            <button>Quero Saber Mais</button>

          </div>
          <div className='zuc-images'>

            <img src={require("../../assets/clip-servicos.png")} className='zuc-details-image ' />

          </div>


        </div>

      </div>
      <div className="zuc-details-container">
        <div className="zuc-details-content">
          <div className='zuc-images'>

            <img src={require("../../assets/clip-relatorio.png")} className='zuc-details-image ' />

          </div>

          <div className="zuc-details-text" >
            <div className="zuc-details-title">
              <FontAwesomeIcon icon={faChartLine} id="service-icon-zuc" />
              <span>Relatórios</span>
            </div>
            <div>

            </div>
            <h1>
              Chega de se perder em relatórios! Organize tudo num Clipp
            </h1>
            <p >
              No Clipp você pode escolher entre diversas opções de relatórios para ter sempre à mão dados precisos e fáceis de entender. Afinal, uma boa gestão não se faz só pela quantidade de relatórios, mas também pela qualidade e entendimento das informações.

            </p>
            <h3>
              Dashboard Personalizável
            </h3>

            <span>
              Tela para acompanhamento dos dados comerciais, estoque, vendas e pagamentos.
            </span>
            <h3>
              Do Estoque às Vendas
            </h3>
            <span>
              Gere relatórios de compras, vendas, comissões e muito, mas muito mais!         </span>
            <button id='btnCenter'>Quero Saber Mais</button>


          </div>



        </div>

      </div>
      <div className="zuc-details-container" id='whiteBg'>
        <div className="zuc-details-content" id='j-column'>


          <div className="zuc-details-text" id='alignStart'>
            <div className="zuc-details-title">
              <FontAwesomeIcon icon={faUtensils} id="service-icon-zuc" />
              <span>Restaurante</span>
            </div>
            <h1>

              Com o Clipp, o seu pedido de sistema confiável será atendido
            </h1>
            <p >
              Conte com uma solução pensada para atender e controlar a gestão do seu bar, restaurante ou comércio de alimentos. Além de tudo que já mostramos, você tem praticidade para gerenciar pedidos, cardápio, comandas e muito mais.
            </p>
            <h3>
              Integração com iFood           </h3>
            <span>
              Abra sua loja na maior plataforma de alimentação do Brasil e receba seus pedidos no Clipp.          </span>
            <h3>
              Alerta de Tempo sem Consumo
            </h3>
            <span>
              O garçom será avisado quando uma mesa estiver a um certo tempo sem consumir.</span>
            <button>Quero Saber Mais</button>

          </div>
          <div className='zuc-images'>
            <img src={require("../../assets/clip-restaurante-bg.png")} className='zuc-details-image ' />

            <img src={require("../../assets/clip-restaurante.png")} className='zuc-details-image ' />

          </div>

        </div>

      </div>
      <div className="zuc-details-container">
        <div className="zuc-details-content">
          <div className='zuc-images'>
            <img src={require("../../assets/clip-vendas-bg.png")} className='zuc-details-image ' />

            <img src={require("../../assets/clip-vendas.png")} className='zuc-details-image ' />

          </div>
          <div className="zuc-details-text" >
            <div className="zuc-details-title">
              <FontAwesomeIcon icon={faMobileScreenButton} id="service-icon-zuc" />
              <span>Venda Online</span>
            </div>
            <div>

            </div>
            <h1>
              Não é só a gestão que pode ser online. Comece a vender pela internet!
            </h1>
            <p >
              Além de todas as funções que facilitam a gestão do seu negócio, o Clipp também tem integrações para você aumentar seus resultados oferecendo produtos e serviços na internet.
            </p>
            <h3>
              Integração com Mercado Livre
            </h3>

            <span>
              Integre o seu negócio com um dos maiores portais de venda online do Brasil.
            </span>
            <h3>
              Integração com WhatsApp
            </h3>
            <span>
              Além de vender, você também pode manter o relacionamento em dia com seus clientes.        </span>
            <button id='btnCenter'>Quero Saber Mais</button>


          </div>



        </div>

      </div>
    </section>
  );
}

export default DetialsClip;