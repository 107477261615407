import { useState, useEffect } from "react";
import { Link, animateScroll as scroll, scrollSpy } from 'react-scroll'
import { useScreen } from "../providers/ScreenSize";
import { useLocation } from 'react-router-dom';
import { Navbar, Nav, Container, Form, FormControl, Button } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link as Navigate } from "react-router-dom";
import { useHover } from "../providers/ChangeOnHover";
import { FaSearch } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';


function Header() {
  const [show, setShow] = useState(false);

  const handleMouseEnter = () => setShow(true);
  const handleMouseLeave = () => setShow(false);
  const { isSmallScreen } = useScreen();
  const [isnavVisible, setIsNavVisible] = useState(false);
  const location = useLocation().pathname;
  const hash = useLocation().hash;
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setIsNavVisible(!isnavVisible);
  };

  const [searchQuery, setSearchQuery] = useState('');
  const handleNavigate = (id) => {
    navigate(`/#${id}`);
    setExpanded(false);
  };
  const handleNavigateTake = (id) => {
    navigate(`/Take#${id}`);
    setExpanded(false);
  };

  useEffect(() => {
    // Handler to call on screen resize
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      window.innerWidth <= 991 && setShow(true)
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  useEffect(() => {
    const handleScroll = () => setExpanded(false);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  return (
    <header >
      <Navbar expand="lg" className="custom-navbar" expanded={expanded} >
        <Container id="nav">
          <Navbar.Brand href="/" className="logo">
            <img className="logo-img" src={require("../assets/Branco.png")} alt="logo-img" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} // Toggle expanded state on click
            style={{ borderColor: '#fff', marginRight: '20px', 'fontSize': '1rem' }} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav id="ul">
              <Link
                spy={true}
                smooth={true}
                duration={600}
                className="nav-options"
                onClick={() => handleNavigate('solucoes')}

              >
                Soluções

              </Link>

              {screenWidth > 991 ? (<>
                <NavDropdown title="Para sua empresa" show={show}
                  onMouseEnter={handleMouseEnter} onScroll={handleMouseLeave}
                  onMouseLeave={handleMouseLeave} onClick={handleMouseEnter} id="nav-dropdown" className="custom-dropdown-menu" >
                  <Navigate
                    to="Zuchetti"
                    spy={true}
                    smooth={true}
                    duration={600}
                    className="nav-options"

                  >Sistema de gestão</Navigate>
                  <NavDropdown.Divider />
                  <Navigate
                    to="Certificado"
                    spy={true}
                    smooth={true}
                    duration={600}
                    className="nav-options"

                  >
                    Certificado Digital
                  </Navigate>
                  <NavDropdown.Divider />
                  {/* <Navigate
                    to="Lumi"
                    spy={true}
                    smooth={true}
                    duration={600}
                    className="nav-options"

                  >Economia de Energia   </Navigate>
                  <NavDropdown.Divider /> */}
                  <Navigate

                    to="Take"
                    spy={true}
                    smooth={true}
                    duration={600}
                    className="nav-options"

                  >
                    Conveniência inteligente
                  </Navigate>
                </NavDropdown></>) : (
                <>
                  <Navigate
                    to="Zuchetti"
                    spy={true}
                    smooth={true}
                    duration={600}
                    className="nav-options"
                    onClick={() => setExpanded(!expanded)}
                  >Clipro</Navigate>
                  <Navigate
                    to="Certificado"
                    spy={true}
                    smooth={true}
                    duration={600}
                    className="nav-options"
                    onClick={() => setExpanded(!expanded)}
                  >
                    Certificado Digital
                  </Navigate>
                  {/* <Navigate
                    to="Lumi"
                    spy={true}
                    smooth={true}
                    duration={600}
                    className="nav-options"
                    onClick={() => setExpanded(!expanded)}
                  >Economia de Energia   </Navigate> */}
                  <Navigate

                    to="Take"
                    spy={true}
                    smooth={true}
                    duration={600}
                    className="nav-options"
                    onClick={() => setExpanded(!expanded)}
                  >
                    Comodidade
                  </Navigate>
                </>
              )}
              <Link

                spy={true}
                smooth={true}
                duration={600}
                onClick={() => handleNavigate('sobre')}

                className="nav-options"
              >Institucional   </Link>
              <Link
                onClick={() => handleNavigateTake('insta')}
                spy={true}
                smooth={true}
                duration={600}
                className="nav-options"
              >Conteúdos   </Link>
              <Link
                spy={true}
                smooth={true}
                duration={600}
                className="nav-options"
                onClick={() => handleNavigate('contato')}
              >Contato   </Link>
              {/* 
              <div>
                <FaSearch id="search-icon" />

              </div> */}

            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>

    // <header>
    //   <a href="/index.html">
    //     <div className="logo">
    //     </div>
    //   </a>
    //   <nav>
    //     <ul>
    //       <li>Soluções</li>

    //       <li>Para sua empresa</li>
    //       <li>Institucional</li>
    //       <li>Conteúdos</li>
    //       <li>Contato</li>
    //       <i id="search-icon" className="fa-solid fa-magnifying-glass"></i>
    //     </ul>
    //   </nav>

    //   <i id="menu-icon" className="fa-solid fa-bars"></i>

    // </header>

  );
}

export default Header;
