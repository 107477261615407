import React, { Component, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { v4 as uuidv4 } from "uuid";
import Card from ".././Card";
import Carousel from ".././Carousel";
import mixmisto from '../../assets/mix misto.png'
import mixnao from '../../assets/mix não alcoólico.png'
import mixsim from '../../assets/mix alcoólico (1).png'
import redbull from '../../assets/red bull.png'
import pepsico from '../../assets/pepsico.png'

function Marcas(props) {



  let cards = [
    {
      key: uuidv4(),
      content: (
        <Card imagen={mixsim} title={'Mix alcoólico'} text={<span>Nosso mix alcoólico inclui cervejas premium, como Heineken, Corona, Spaten, Stella e Budweiser. Todas são no tamanho long neck, de 330ml e ficam na temperatura ideal de até -5º. Além disso, contamos com alguns drinks prontos, como Smirnoff Ice, Skol Beats e Beats Tropical</span>} btnText={'Quero esse!'} saibaMais={`https://wa.me/556198810018?text=Oie!%20Vi%20o%20site%20da%20JFH%20e%20gostaria%20de%20ter%20mais%20detalhes%20sobre%20o%20mix%20alcoólico.`} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={mixnao} title={'Mix não alcoólico'} text={<span>Nosso mix não alcoólico inclui gatorade, sucos da natural one, águas com e sem gás, redbull, monster, coca-cola, pepsi, guaraná, sukita e whey piracanjuba. Todos no tamanho individual e na temperatura ideal de até 8º.</span>} btnText={'Quero esse!'} saibaMais={`https://wa.me/556198810018?text=Oie!%20Vi%20o%20site%20da%20JFH%20e%20gostaria%20de%20ter%20mais%20detalhes%20sobre%20o%20mix%20não%20alcoólico.`} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={mixmisto} title={'Mix misto'} text={<span>Nosso mix misto inclui tanto cervejas quanto refrigerantes normais. Devido a baixa temperatura ideal para as cervejas (-5º), não é possível colocar outros produtos não alcoólicos, nem refris zero nesse mix, pois eles congelam ou explodem.</span>} btnText={'Quero esse!'} saibaMais={`https://wa.me/556198810018?text=Oie!%20Vi%20o%20site%20da%20JFH%20e%20gostaria%20de%20ter%20mais%20detalhes%20sobre%20o%20mix%20misto.`} />
      )
    }
  ];


  return (
    <div className="destaques-container" id='#solucoes'>
      <div className='with-child' id='cremeBg'>


        <div className="destaques-container-title">
          <div id='title'>
            <h2>Nossos produtos</h2>
            <h1>Variedade de mixes de bebidas</h1>
            <div className="divider" id="destaques-container-divider">

            </div>
          </div>

          <div className="destaques-content">


            <Carousel
              cards={cards}
              height="500px"
              width="100%"
              margin="0px"
              offset={2}
              showArrows={false}

            />

          </div>


        </div>

        {/* <img src={cinza} id='bg-left' alt="Avatar" />



        <img src={cinza} id='bg-right' alt="Avatar" /> */}
      </div>

    </div>
  );
}

export default Marcas;
