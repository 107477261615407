import React from 'react';
import wpp from '../assets/wpp.png'
function FlashingImage() {
    return (
        <div>
            <img
                src={wpp} alt="Flashing Example"
                className="flashing floating-image"
                onClick={() => window.location.href = "https://wa.me/61998867848"}
            />
        </div>
    );
}

export default FlashingImage;