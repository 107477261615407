import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clipproVideo from '../../assets/clippro-video.gif'
import { faMoneyBill, faAddressCard, faCoins, faFileInvoiceDollar, faRobot, faPrint, faLock, faPlus, faDollarSign, faHouse, faChartSimple, faBold, faBolt, faRegistered, faMoneyBillTransfer, faBoxesStacked, faChartLine, faUtensils, faShield, faMobile, faMobileScreenButton, faCircleCheck } from '@fortawesome/free-solid-svg-icons'


function DetialsClip() {
  const benefits = [
    'Tecnologia de ponta em certificação digital',
    'Suporte técnico especializado',
    'Atendimento agendado ou imediato',
    'Benefícios exclusivos para clientes',
    'Compromisso com a sua segurança',
    'Agilidade e facilidade do início ao fim do processo',
    'Uma enorme gama de produtos'

  ]
  return (
    <section className="zuc-details">
      <div className="zuc-details-container">
        <div className="zuc-details-content" id='column-not-reverse'>
          <div className='lider-digital-title'>
            <div id='title'>

              <h1>

                Por que fazer meu certificado com a JFH?
              </h1>
              <div id="divider-lider" >

              </div>
            </div>
            <button onClick={() => window.location.href = 'https://wa.me/556198810018?text=Oie!%20Vi%20o%20site%20da%20JFH%20e%20gostaria%20de%20ter%20mais%20detalhes%20sobre%20os%20certificados%20digitais.'} >Solicitar certificado agora</button>
          </div>
          <div className="lider-details-text">
            {
              benefits.map((benefit, index) => {
                return (
                  <div className="lider-details-title">
                    <FontAwesomeIcon icon={faCircleCheck} id="service-icon-lider" />
                    <span>{benefit}</span>
                  </div>
                )
              })
            }




          </div>



        </div>

      </div>

    </section>
  );
}

export default DetialsClip;