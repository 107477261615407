
import Button from "react-bootstrap/Button";
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'

function Capa(props) {


  return (
    <>
      <div className="modal-container">

      </div>
      <div className="home-container">

      </div>
      <div class="main-text-container">
        <span>A vida acontece na simplicidade <FontAwesomeIcon icon={faExclamation} /></span>
      </div>
    </>
  );
}


export default Capa;
