import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import card1 from '../../assets/certificado digital A1 feed (2).png'
import card2 from '../../assets/Capa LD.png'
import card3 from '../../assets/card 1 LD.png'
import card4 from '../../assets/CARD 2 LD.png'
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import { useScreen } from "../../providers/ScreenSize";
import { AutoPlay } from "@egjs/flicking-plugins";




function CarrouselShow(props) {
    const plugins = [new AutoPlay({ duration: 3000, direction: "NEXT", stopOnHover: true })];
    const feeds = [card1, card3, card4]




    return (
        <div className="lider-flicking">
            <div className="padding-flicking">
                <img className="capa" src={card2} alt={''} />

                <Flicking
                    className="horizontal-carousel"
                    circular={true}
                    autoplay={true}
                    duration={700}
                    horizontal={true}
                    vertical={false}
                    plugins={plugins}// Set the carousel to vertical
                >
                    {feeds.map((post, index) => {

                        return (
                            <div key={index} className="carousel-panel">
                                {post.media_type === 'VIDEO' ? (
                                    <video className="carrouselVideo" autoPlay muted webkit-playsinline playsinline  >
                                        <source src={post} type="video/mp4" />
                                    </video>


                                ) : (<img src={post} alt={post.text} className="rotating" />
                                )

                                }
                            </div>
                        )
                    })}
                </Flicking>
            </div>

        </div>


    );
}

export default CarrouselShow;
