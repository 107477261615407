import { FaInstagram, FaRegClock, FaWhatsapp } from "react-icons/fa";

function Footer() {
  return (
    <footer >
      <div className="footer-content">

        <div className="info-container" >
          <img src={require("../assets/Branco (1).png")} alt="signature" />


          <span >Fique por dentro das nossas novidades!</span>
        </div>
        <div className="">
          <span >&copy; JFH Tecnologias Inteligentes</span>
        </div>
        <div className="info-container" >


          <FaInstagram className="icon" />


          <span >@jfhtech
          </span>



          <FaWhatsapp className="icon" />

          <span >(61) 9 9886-7848
          </span>

        </div>
      </div>

    </footer>
  );
}

export default Footer;
