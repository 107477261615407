import React, { Component, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { v4 as uuidv4 } from "uuid";
import Card from "./Card";
import Carousel from "./Carousel";
import takeLogo from '../assets/takeLogo.png'
import zuchettiLogo from '../assets/zucchetti-logo.png'
import lumiLightLogo from '../assets/lumilight-logo.webp'
import liderlogo from '../assets/liderDigital.png'

import cinza from '../assets/Cinza.png'
function Destaques(props) {



  let cards = [
    {
      key: uuidv4(),
      content: (
        <Card imagen={takeLogo} key={'take'} nav={'Take'} title={'Conveniência mais segura!'} text={<span>Com a Take você experimenta o que é hiperconveniência. Com sistema inovador, deixamos de lado o sistema de confiança, e implementamos precisão, tecnologia e segurança. Adeus, inadimplência!</span>} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={zuchettiLogo} key={uuidv4()} nav={'Zuchetti'} title={'Sistema eficiente, gestão inteligente!'} text={'O coração da gestão do seu negócio tem nome: sistema de software. Tome decisões certeiras e tranquilas! Ter as informações do seu negócio em um só lugar vai acelerar seu crescimento e diminuir seus custos. ERP: as três letrinhas da magia empresarial!'} />

      )
    },
    // {
    //   key: uuidv4(),
    //   content: (
    //     <Card imagen={lumiLightLogo} key={uuidv4()} nav={'Lumi'} title={'Energia de qualidade é com a Lumilight'} text={'O equipamento que você precisa dar “tchau” a equipamentos queimados e conta de energia cara. Dê “oi” para economia e desempenho elétrico. Limpe sua corrente elétrica agora mesmo'} />

    //   )
    // },
    {
      key: uuidv4(),
      content: (
        <Card imagen={liderlogo} key={uuidv4()} nav={'Certificado'} title={'Assine documentos em segundos!'} text={'Assinar documentos nunca foi tão fácil. A certificação digital garante validade jurídica para suas assinaturas. A emissão é online pra você fazer aonde estiver. Seu tempo vale ouro!'} />

      )
    },

  ];


  return (
    <div className="destaques-container" id='#solucoes'>
      <div className='with-child' id='cremeBg'>


        <div className="destaques-container-title">
          <div id='title'>
            <h2>NOSSAS SOLUÇÕES</h2>
            <h1>Hub de negócios</h1>
            <div className="divider" id="destaques-container-divider">

            </div>
          </div>

          <div className="destaques-content">


            <Carousel
              cards={cards}
              height="400px"
              width="100%"
              margin="0px"
              offset={2}
              showArrows={false}

            />

          </div>


        </div>

        {/* <img src={cinza} id='bg-left' alt="Avatar" />



        <img src={cinza} id='bg-right' alt="Avatar" /> */}
      </div>

    </div>
  );
}

export default Destaques;
