import logo from "./logo.svg";
import "./App.css";
import React from "react";
import "@fontsource/poppins";
import { HoverProvider, useHover } from "./providers/ChangeOnHover";
import { ScreenSizeProvider, useScreen } from "./providers/ScreenSize";
import Take from "./components/Take/Take";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Navigate, Routes, BrowserRouter } from 'react-router-dom';
import Main from "./components/Main";
import LumiLight from "./components/Lumilight/LumiLight";
import Zuchetti from "./components/Zuchetti/Zuchetti";
import Certificado from "./components/Zuchetti Cert/Certificado";
import FlashingImage from "./components/FlashingImage";
function App() {
  // const [homePage, setHomepage] = React.useState(true);

  // const productArray = [
  //   ...albumArray,
  //   {
  //     image: hours24,
  //     text: `Cerveja Gelada a hora que voce quiser!🍻`,
  //     title: `Take and Go | Cerveja gelada sem sair de casa`,
  //   },
  // ];

  const { hover } = useHover();

  return (
    <>

      <Router>


        <Header ></Header>
        <Routes>

          <Route exact path="/" element={<Main />} />

          <Route exact path="/Take" element={<Take />} />
          {/* <Route exact path="/Lumi" element={<LumiLight />} /> */}
          <Route exact path="/Zuchetti" element={<Zuchetti />} />
          <Route exact path="/Certificado" element={<Certificado />} />

        </Routes>

        <Footer style={{ border: '2px solid red' }}></Footer>
        <FlashingImage />

      </Router>

    </>





  );
}

function Root() {
  return (
    <HoverProvider>
      <ScreenSizeProvider>
        <App />
      </ScreenSizeProvider>
    </HoverProvider>
  );
}

export default Root;