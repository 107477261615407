
import Button from "react-bootstrap/Button";
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import '../../providers/Take.css'

function CapaTake(props) {


  return (
    <>
      <div className="modal-container-take">

      </div>
      <div className="home-container-take">
        <div className="take-capa-container">
          <div className="take-capa-text">
            <h1>
              Bebidas geladas
              <div >
                a poucos
              </div>
              <h1>
                passos de você, sempre!
              </h1>


            </h1>
          </div>
          <div className="take-capa-image">
            <img src={require("../../assets/geladeiras2.png")} alt="geladeiras" />

          </div>
        </div>
      </div>


      {/* <div class="main-text-container">
        <span>A vida acontece na simplicidade <FontAwesomeIcon icon={faExclamation} /></span>
      </div> */}
    </>
  );
}


export default CapaTake;
