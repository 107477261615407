
import Destaques from "../components/Destaques";
import Sobre from "../components/Sobre";
import Services from "../components/Services";
import Contate from "../components/Contate";
import Testimonials from "../components/Testimonials";
import Capa from "../components/Capa";
import Footer from "./Footer";
import Header from "./Header";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { Element } from 'react-scroll'
import React, { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

function Main() {
    const location = useLocation();
    useEffect(() => {
        // Scroll to the section if the URL contains a hash
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <>


            <Capa />
            <Element id='solucoes' name='solucoes'>
                <Destaques style={{ border: '2px solid red' }}></Destaques>
            </Element>
            <Element id='sobre' name='sobre'>
                <Sobre />
            </Element>
            {/* <Services /> */}

            {/* <Element id='testimonios' name='testimonios'>

                <Testimonials style={{ border: '2px solid red' }} />
            </Element> */}
            <Element id='contato' name='contato'>

                <Contate style={{ border: '2px solid red' }} />
            </Element>

        </>
    );
}

export default Main;
