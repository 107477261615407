
import React, { useState, useEffect, useRef } from "react";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
import { faEnvelope, faPhone, faClock, faCamera, faEnvelopeSquare, faEnvelopeOpenText, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaInstagram, FaRegClock, FaWhatsapp } from "react-icons/fa";
import { FaClock } from "react-icons/fa6";

init(process.env.REACT_APP_EMAIL_INIT);
function Contate(props) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name: name,
      email: email,
      message: message,
      number: phone
    };

    emailjs.send('service_73e6icp', 'JFH_contato', templateParams, 'FT7HmVZjaACob92iB')
      .then((response) => {
        console.log('Email sent successfully', response);
        alert('Message sent!');
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');


      })
      .catch((error) => {
        console.log('Error sending email', error);
        alert('Failed to send message.');
      });

  };

  return (
    <div className="form-container" id='contato'>
      <div className='padding-contact'>
        <div className="align-form">
          <div className="form-content">
            <div className="form-title">

              <h1>Fale Conosco</h1>
              <div className="divider" id="form-divider"></div>
            </div>
            <p className="form-description">
              Como podemos te ajudar? <br />Mande sua dúvida, solicitação de orçamento ou comentário. Te responderemos em breve!
            </p>
            <form className="form-inputs" >
              <div className="first-inputs">
                <div className="input-container" id="user-name-input">
                  <input
                    type="text"
                    name="user-name"
                    value={name}
                    className="user-input"
                    placeholder="Nome"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="input-container" id="user-email-input">
                  <input
                    type="email"
                    name="user-email"
                    value={email}
                    className="user-input"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="input-container" id="user-phone-input">
                <input
                  type="text"
                  name="user-phone"
                  value={phone}
                  className="user-input"
                  placeholder="Telefone"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="input-container" id="user-message-input">
                <textarea
                  placeholder="Mensagem"
                  id="user-message"
                  name="user-message"
                  className="user-input"
                  cols="30"
                  rows="10"
                  maxLength="350"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>

            </form>
            <button type="submit" className="submit-button" disabled={name.length <= 0 || email.length <= 0 || message.length <= 0} onClick={handleSubmit}>Enviar</button>
          </div>
        </div>



        <div className="form-img">

        </div>


        <div className="information-container">
          <div className="information-content">

            <div className="info-description">
              <h2>Informações</h2>

              <div className="info-item">

                <div className="info-item-icon">

                  <FontAwesomeIcon icon={faEnvelopeOpenText} id='infoIcon' />
                </div>
                <span className="service-description">atendimento@jfhdelivery.com.br
                </span>

              </div>
              <div className="info-item">

                <div className="info-item-icon">
                  <FaWhatsapp id='infoIcon' />
                </div>
                <span className="service-description">(61) 9 9886-7848
                </span>

              </div>
              <div className="info-item">

                <div className="info-item-icon">
                  <FaInstagram id='infoIcon' />

                </div>
                <span className="service-description">@jfhtech
                </span>

              </div>
              <div className="info-item">

                <div className="info-item-icon">
                  <FaRegClock id='infoIcon' />
                </div>
                <span className="service-description">Segunda a sexta de 9h às 18h
                </span>

              </div>

            </div>




          </div>

        </div>
      </div>
    </div>


  );
}

export default Contate;
