

import '../../providers/Zuchetti.css'
import { Element } from 'react-scroll'

import Marcas from './Marcas';
import SobreZuc from "./SobreZuc";
import ServicesZuc from './ServicesZuc';
import CapaZuchetti from './Capa-Zuchettii';
import DetialsClip from './DetailsClip';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CarrouselShow from './Carrousel';
import Cert from './Cert';
function Zuchetti() {

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <>


            {/* <CapaZuchetti /> */}
            <Element id='capa' name='capa'>
                <SobreZuc />
            </Element>
            <Element id='solucoes' name='solucoes'>
                <Marcas
                ></Marcas>
            </Element >
            <Element id='sobre' name='sobre'>
                <ServicesZuc />
            </Element>
            <Element id='carrousel' name='carrousel'>
                <CarrouselShow />
            </Element>
            <Element id='certificado' name='certificado'>
                <Cert />
            </Element>
            {/* <Element id='details' name='details'>
                <DetialsClip />
            </Element> */}




        </>
    );
}

export default Zuchetti;
