import Carousel from "react-spring-3d-carousel";
import { useState, useEffect } from "react";
import { config } from "react-spring";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button';

export default function Carroussel(props) {
    const table = props.cards.map((element, index) => {
        return { ...element, onClick: () => setGoToSlide(index) };
    });

    const [offsetRadius, setOffsetRadius] = useState(1);
    const [showArrows, setShowArrows] = useState(false);
    const [goToSlide, setGoToSlide] = useState(0);
    const [cards] = useState(table);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        console.log(window.innerWidth)
        if (window.innerWidth > '1024') {
            setOffsetRadius(1)

        }
        if (window.innerWidth < '1024') {
            setOffsetRadius(3)
            console.log(3)
        }
        if (window.innerWidth < '900') {
            setShowArrows(true)
            console.log(3)
        }


        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setOffsetRadius(props.offset);
        setShowArrows(props.showArrows);
    }, [props.offset, props.showArrows]);

    return (
        <div
            style={{ width: props.width, height: props.height, margin: props.margin, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
        >
            <Carousel
                slides={cards}
                goToSlide={goToSlide}
                offsetRadius={1}
                showNavigation={showArrows}
                animationConfig={config.gentle}
            />

            <div className="arrow-options-container" >
                <FontAwesomeIcon icon={faArrowLeft} id='arrow-options' onClick={() => setGoToSlide(goToSlide - 1)} />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', columnGap: '20px' }}>



                    {
                        props.cards.map((element, index) => {
                            return (
                                <div className="carousel-nav" >
                                    <FontAwesomeIcon icon={faCircle} onClick={() => setGoToSlide(index)} />

                                </div>
                            )
                        })
                    }



                </div>
                <FontAwesomeIcon icon={faArrowRight} id='arrow-options' onClick={() => setGoToSlide(goToSlide + 1)} />

            </div>

        </div>
    );
}
