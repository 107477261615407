

import '../../providers/Zuchetti.css'
import { Element } from 'react-scroll'

import Marcas from './Marcas';
import SobreZucCert from './SobreZucCert'
import ServicesZuc from './ServicesZuc';
import DetialsClip from './DetailsClip';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CarrouselShow from './Carrousel';

function Certificado() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <>


            {/* <CapaZuchetti /> */}
            <Element id='sobre' name='sobre'>
                <SobreZucCert />
            </Element>
            <Element id='sobre' name='sobre'>
                <Marcas />
            </Element>
            <Element id='details' name='details'>
                <CarrouselShow />
            </Element>
            {/* <Element id='services' name='services'>
                <ServicesZuc />
            </Element> */}
            <Element id='details' name='details'>
                <DetialsClip />
            </Element>


            {/* <Element id='solucoes' name='solucoes'>
                <Marcas
                ></Marcas>
            </Element > */}


        </>
    );
}

export default Certificado;
