
import React, { Component, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { v4 as uuidv4 } from "uuid";
import Card from ".././Card";
import Carousel from ".././Carousel";



function Marcas(props) {



  let cards = [

    {
      key: uuidv4(),
      content: (
        <Card title={'E-CPF A1'} text={<span> Segurança digital pessoal instantânea</span>} saibaMais={`https://wa.me/556198810018?text=Oie!%20Vi%20o%20site%20da%20JFH%20e%20gostaria%20de%20ter%20mais%20detalhes%20sobre%20os%20certificados%20digitais.`} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card title={'E-CPF A3'} text={<span>Autenticação confiável para suas necessidades.</span>} saibaMais={`https://wa.me/556198810018?text=Oie!%20Vi%20o%20site%20da%20JFH%20e%20gostaria%20de%20ter%20mais%20detalhes%20sobre%20os%20certificados%20digitais.`} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card title={'E-CNPJ A1'} text={<span> Empodere sua empresa com segurança digital ágil.
        </span>} saibaMais={`https://wa.me/556198810018?text=Oie!%20Vi%20o%20site%20da%20JFH%20e%20gostaria%20de%20ter%20mais%20detalhes%20sobre%20os%20certificados%20digitais.`} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card title={'E-CNPJ A3'} text={<span> Segurança digital robusta para empresas confiáveis.
        </span>} saibaMais={`https://wa.me/556198810018?text=Oie!%20Vi%20o%20site%20da%20JFH%20e%20gostaria%20de%20ter%20mais%20detalhes%20sobre%20os%20certificados%20digitais.`} />
      )
    }

  ];


  return (
    <div className="destaques-container" id='#solucoes'>
      <div className='with-child' id='cremeBg'>


        <div className="destaques-container-title">
          <div id='title'>
            <h2>CERTIFICAÇÃO DIGITAL</h2>
            <h1>Temos o certificado
              que se encaixa com as
              suas necessidades.</h1>
            <div className="divider" id="destaques-container-divider">

            </div>
          </div>

          <div className="destaques-content">


            <Carousel
              cards={cards}
              height="300px"
              width="90%"
              margin="0px"
              offset={2}
              showArrows={false}

            />

          </div>


        </div>

        {/* <img src={cinza} id='bg-left' alt="Avatar" />



        <img src={cinza} id='bg-right' alt="Avatar" /> */}
      </div>

    </div>
  );
}


export default Marcas;
