import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clipproVideo from '../../assets/clippro-video.gif'
import { faMoneyBill, faAddressCard, faCoins, faFileInvoiceDollar, faRobot, faPrint, faLock, faPlus, faDollarSign, faHouse, faChartSimple, faBold, faBolt, faRegistered, faMoneyBillTransfer, faBoxesStacked, faChartLine, faUtensils, faShield, faMobile, faMobileScreenButton } from '@fortawesome/free-solid-svg-icons'


function Cert() {
  return (
    <section className="zuc-details">
      <div className="zuc-details-container">

        <div className='zuc-images'>
          <img src={require("../../assets/certificado Parceiro-1.png")} className='zuc-details-image ' />
        </div>





      </div>

    </section>
  );
}

export default Cert;