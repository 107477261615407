
import React, { Component, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { v4 as uuidv4 } from "uuid";
import Card from ".././Card";
import Carousel from ".././Carousel";
import clipservice from '../../assets/clipp_service-no-bg.png'
import clipfacil from '../../assets/clipp_fácil-removebg-preview.png'
import clipp360 from '../../assets/clipp_360-removebg-preview.png'
import clippMei from '../../assets/clipp_mei-removebg-preview.png'
import clippChef from '../../assets/clipp_chef-removebg-preview.png'
import clippPro from '../../assets/clipp_pro-removebg-preview.png'




function Marcas(props) {



  let cards = [
    {
      key: uuidv4(),
      content: (
        <Card imagen={clippPro} title={'Clipp Pro'} text={<span> A solução de gestão de empresas mais completa do mercado e mais utilizada no micro e pequeno varejo brasileiro.<b>Tem o melhor custo-benefício.</b>
        </span>} saibaMais={`https://wa.me/556198810018?text=Oie!%20Vi%20o%20site%20da%20JFH%20e%20gostaria%20de%20ter%20mais%20detalhes%20sobre%20os%20sistemas%20de%20gestão.`} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={clipfacil} title={'Clipp Fácil'} text={<span> A solução simples, prática e fácil de usar na palma da sua mão, na hora e local que desejar! Não há necessidade de instalação desktop.</span>}
          saibaMais={`https://wa.me/556198810018?text=Oie!%20Vi%20o%20site%20da%20JFH%20e%20gostaria%20de%20ter%20mais%20detalhes%20sobre%20os%20sistemas%20de%20gestão.`}
        />
      )
    },

    {
      key: uuidv4(),
      content: (
        <Card imagen={clipp360} title={'Clipp 360'} text={<span> A solução em nuvem para uma visão completa do seu negócio.</span>} saibaMais={`https://wa.me/556198810018?text=Oie!%20Vi%20o%20site%20da%20JFH%20e%20gostaria%20de%20ter%20mais%20detalhes%20sobre%20os%20sistemas%20de%20gestão.`} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={clippMei} title={'Clipp Mei'} text={<span>A Solução ideal para acelerar os processos e desenvolver a gestão da sua empresa MEI, simplificando o controle e registro das movimentações</span>} saibaMais={`https://wa.me/556198810018?text=Oie!%20Vi%20o%20site%20da%20JFH%20e%20gostaria%20de%20ter%20mais%20detalhes%20sobre%20os%20sistemas%20de%20gestão.`} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={clipservice} title={'Clipp Service'} text={<span> A solução ideal para o gerenciamento da prestação de serviços em micro e pequenas empresas.
          Permite a configuração e emissão de NFSe e possibilita cadastramento de objetos, equipamentos, agendamentos e locações.
        </span>} saibaMais={`https://wa.me/556198810018?text=Oie!%20Vi%20o%20site%20da%20JFH%20e%20gostaria%20de%20ter%20mais%20detalhes%20sobre%20os%20sistemas%20de%20gestão.`} />
      )
    },
    {
      key: uuidv4(),
      content: (
        <Card imagen={clippChef} title={'Clipp Chef'} text={<span> A solução completa para bares, restaurantes e similares.
          Desenvolvido para atender e controlar o fluxo do seu estabelecimento com agilidade e segurança
        </span>} saibaMais={`https://wa.me/556198810018?text=Oie!%20Vi%20o%20site%20da%20JFH%20e%20gostaria%20de%20ter%20mais%20detalhes%20sobre%20os%20sistemas%20de%20gestão.`} />
      )
    }

  ];


  return (
    <div className="destaques-container" id='#solucoes'>
      <div className='with-child' id='cremeBg'>


        <div className="destaques-container-title">
          <div id='title'>
            <h2>Nossos Softwares</h2>
            <h1>A opção ideal para o seu negócio</h1>
            <div className="divider" id="destaques-container-divider">

            </div>
          </div>

          <div className="destaques-content">


            <Carousel
              cards={cards}
              height="400px"
              width="90%"
              margin="0px"
              offset={2}
              showArrows={false}

            />

          </div>


        </div>

        {/* <img src={cinza} id='bg-left' alt="Avatar" />



        <img src={cinza} id='bg-right' alt="Avatar" /> */}
      </div>

    </div>
  );
}

export default Marcas;
