import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Feed from "./Feed";
import Card from "react-bootstrap/Card";
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import { useScreen } from "../providers/ScreenSize";
import { AutoPlay } from "@egjs/flicking-plugins";
import instalogo from '../assets/instalogo.png'
import ReactPlayer from 'react-player'


const InstaFeeds = ({ token, ...props }) => {
  const [feeds, setFeedsData] = useState([]);
  const [showmore, setShowMore] = React.useState(false);
  const { isSmallScreen } = useScreen();
  const flickingRef = useRef(null); // Reference to the Flicking instance
  const panels = [
    { src: "https://via.placeholder.com/300x200", alt: "Slide 1" },
    { src: "https://via.placeholder.com/300x200", alt: "Slide 2" },
    { src: "https://via.placeholder.com/300x200", alt: "Slide 3" },
    { src: "https://via.placeholder.com/300x200", alt: "Slide 4" },
    { src: "https://via.placeholder.com/300x200", alt: "Slide 5" },
  ];

  const moveToPrev = () => {
    flickingRef.current.moveToPrev();
  };

  const moveToNext = () => {
    flickingRef.current.moveToNext();
  };
  const plugins = [new AutoPlay({ duration: 3000, direction: "NEXT", stopOnHover: true })];

  //use useRef to store the latest value of the prop without firing the effect
  const tokenProp = useRef(token);
  tokenProp.current = token;

  const rows = [...Array(Math.ceil(feeds.length / 3))];
  const productRows = rows.map((row, idx) => feeds.slice(idx * 3, idx * 3 + 3));

  useEffect(() => {
    // this is to avoid memory leaks
    const abortController = new AbortController();

    async function fetchInstagramPost() {
      try {
        axios
          .get(
            `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&limit=4&access_token=IGQWRPdXBBZAzNMWG0xMDZAPVzNKTm52Y2k2MnVNbGZApLTMwWlV3RkFGVXYxcnFWbFBvVmV4aGtFYzNzbmFxc20zZADhQTFZAqRFlTdm9PZAFNLRHNQYngwMHVTeFl4VnZAkMEN6VWpJWWlRZAnFSVlB2ME1mS1lkSVNHZAGcZD`
          )
          .then((resp) => {
            let feeds2 = resp.data.data;

            setFeedsData(
              feeds2.map((item) => {
                return {
                  image: item.media_url,
                  text: item.caption,
                  title: item.caption,
                  media_type: item.media_type,
                };
              })
            );
          });
      } catch (err) {
        console.log("error", err);
      }
    }

    // manually call the fecth function
    fetchInstagramPost();

    return () => {
      // cancel pending fetch request on component unmount
      abortController.abort();
    };
  }, [props.limit]);


  return (
    <section className="our-testimonials-container " id='cremeBg'>
      <div className='padding-reverse'>

        <Flicking
          className="vertical-carousel"
          circular={true}
          autoplay={true}
          duration={500}
          horizontal={false}
          vertical={true}
          plugins={plugins}// Set the carousel to vertical
        >
          {feeds.map((post, index) => {
            console.log(post)
            return (
              <div key={index} className="carousel-panel">
                {post.media_type === 'VIDEO' ? (
                  <video className="carrouselVideo" autoPlay muted loop webkit-playsinline playsinline>
                    <source src={post.image} type="video/mp4" />
                  </video>


                ) : (<img src={post.image} alt={post.text} />
                )

                }
              </div>
            )
          })}
        </Flicking>

        <div className="our-testimonials-title">
          <h2>Acompanhe nosso</h2>
          <h1>Instagram</h1>

          <div className="divider" id="our-testimonial-divider">


          </div>
          <img src={instalogo} className="instaLogo" onClick={() => window.location.href = "https://www.instagram.com/takebrasilia/profilecard/?igsh=ZWR1NXQweTBmMmpz"} />

        </div>
      </div>



    </section>
  );
};

export default InstaFeeds;
