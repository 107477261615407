import Styles from "./Card.module.css";
import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import Button from "./Button";
import { useNavigate } from 'react-router-dom';
import StylesBTN from "./Button.module.css";

function Card({ imagen, centered, key, nav, title, text, saibaMais, btnText }) {
    const [show, setShown] = useState(false);
    const [showCentered, setShowCentered] = useState(false);
    const navigate = useNavigate();
    console.log(saibaMais)
    useEffect(() => {

        if (key === centered) {
            setShowCentered(true);
        } else {
            setShowCentered(false);
        }


    }, [centered, key, imagen]);


    const props3 = useSpring({
        opacity: 1,
        transform: show ? "scale(1.03)" : "scale(1)",
        boxShadow: show
            ? "0 20px 25px rgb(0 0 0 / 25%)"
            : "0 2px 10px rgb(0 0 0 / 8%)"
    });

    const handleNavigate = () => {
        nav ?
            navigate(`/${nav}`) :
            window.location.href = `${saibaMais}`;
    }



    return (
        <animated.div
            className={Styles.card}
            style={props3}
            onMouseEnter={() => setShown(true)}
            onMouseLeave={() => setShown(false)}
        >
            <div className={Styles.cardImgDiv}>

                {imagen ? <img src={imagen} alt="" className={nav == 'Certificado' ? Styles.extra : Styles.regular} /> : <h1>{title}</h1>}
            </div>
            {imagen && <h2>{title}</h2>}
            <p>
                {text}
            </p>
            <div className={Styles.btnn} onClick={() => handleNavigate()}>
                <button className={StylesBTN.btn}
                > {btnText ? btnText : 'Saiba Mais'}</button>

            </div>
        </animated.div>
    );
}

export default Card;
