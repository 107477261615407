import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill, faAddressCard, faCoins, faFileInvoiceDollar, faRobot, faPrint, faLock, faPlus, faDollarSign, faHouse, faChartSimple, faBold, faBolt, faRegistered, faMoneyBillTransfer, faBoxesStacked, faChartLine, faUtensils, faShield, faMobile, faMobileScreenButton } from '@fortawesome/free-solid-svg-icons'
import one from '../../assets/8 (1).png'
import two from '../../assets/8.png'
import three from '../../assets/9 (1).png'
import four from '../../assets/9.png'
import seven from '../../assets/10 (1).png'
import five from '../../assets/10.png'
import six from '../../assets/11 (1).png'
import eight from '../../assets/11.png'
import nine from '../../assets/16.png'
import ten from '../../assets/17.png'
import eleven from '../../assets/18.png'
import twelve from '../../assets/7.png'

const brandsArray = [one, two, three, four, five, six, seven, eight, nine, ten, eleven, twelve]

function ServicesZuc() {
  return (
    <section className="our-services-container-zuc">
      <div className='bg'></div>
      <div className="our-services-title" id='s-title-zuc'>
        {/* <h2>Facilite a</h2> */}
        <h1 id='blue'>Integrações de sistema</h1>
        <h2 id='H2center'>com os maiores players</h2>
        <div className="divider" id="our-services-divider">

        </div>

      </div>
      <div className="our-services-content-zuc">
        {
          brandsArray.map((element, index) => {
            return (
              <img src={element} />

            )
          })}

      </div>

    </section>
  );
}

export default ServicesZuc;
