

function Sobre() {
  return (
    <section className="welcome-container">
      <div className="signature-container">
        <div className="signature-content">
          <div className="signature-text">
            <div className="signature-title">
              <h5>Nosso propósito</h5>
              <h1>JFH TECH</h1>
            </div>
            <span>
              A JFH é uma empresa que respira <b>tecnologia e inovação!</b>
            </span>
            <span>
              Combinamos as <b>tecnologias mais inovadoras e disruptivas em um único lugar</b>, nos tornando um <b>hub de negócios</b> que traz para o mercado do DF produtos e soluções com tecnologia de ponta.
            </span>
            <span>Colocando nosso cliente no centro de tudo, nos dedicamos a desafiar suas expectativas e <b>promover um impacto positivo significativo em suas rotinas diárias</b>, além de uma mudança de paradigma no uso da tecnologia</span>
            <div className="signature-button-container">
              <button>Saiba mais</button>
              {/* <img src={require("../assets/Branco (1).png")} alt="signature" className="signature-img" /> */}

            </div>

          </div>
        </div>
        <div className="hub-container">


        </div>
      </div>
    </section>
  );
}

export default Sobre;