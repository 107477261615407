
import Destaques from "../Destaques";
import Sobre from "../Sobre";
import ServicesTake from "./ServicesTake";
import Contate from "../Contate";
import Testimonials from "../Testimonials";
import CapaTake from "./Capa-Take";
import Footer from "../Footer";
import Header from "../Header";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { Element } from 'react-scroll'
import Marcas from "./Marcas";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SobreTake from "./SobreTake";
import InstaFeeds from "../InstFeeds";
function Take() {


    const location = useLocation();
    useEffect(() => {
        // Scroll to the section if the URL contains a hash
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    return (
        <>


            <CapaTake image={'take'} />
            <Element id='services' name='services'>
                <SobreTake />
            </Element>
            {/* <Element id='solucoes' name='solucoes'>
                <Marcas ></Marcas>
            </Element> */}
            <Element id='services' name='services'>
                <ServicesTake />
            </Element>
            <Element id='solucoes' name='solucoes'>
                <Marcas ></Marcas>
            </Element>
            <Element id='testimonios' name='testimonios'>

                <Testimonials style={{ border: '2px solid red' }} />
            </Element>
            <Element id='insta' name='insta'>
                <InstaFeeds />
            </Element>
            {/* <Services /> */}

            {/* <Element id='testimonios' name='testimonios'>

                <Testimonials style={{ border: '2px solid red' }} />
            </Element>
            <Element id='contato' name='contato'>

                <Contate style={{ border: '2px solid red' }} />
            </Element> */}

        </>
    );
}

export default Take;
