import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartSimple, faDollarSign, faMagnifyingGlass, faMinusCircle, faPlus, faPlusCircle, faShield, faStar, faX } from '@fortawesome/free-solid-svg-icons'
import clipproVideo from '../../assets/clippro-video.gif'


function SobrZuc() {
  return (
    <section className="zuc-sobre">
      <div className="zuc-sobre-container">
        <div className="zuc-sobre-content">
          <div className="zuc-sobre-text">
            <div className="zuc-sobre-title">
              <h1>O sistema que facilita a sua gestão.</h1>
              {/* <h5>Fácil como um clique</h5> */}
            </div>
            {/* <span>
              O Filtro capacitivo é um equipamento eletrônico que possui tecnologia autônoma em paralelo em rede, responsável por filtrar e desviar para o solo as interferências causadoras de distorções, incluindo as frequências harmônicas que estão presentes na rede elétrica.
            </span>
            <span>Os Filtros Capacitivos desenvolvidos com exclusividade pela Lumilight do Brasil são blindados, possuindo 0% de índice de umidade interna, otimiza a rede elétrica e corrige as distorções que são responsáveis por causar enormes prejuízos a motores e equipamentos eletrônicos utilizados na indústria, comércio e residências.</span>
 */}




          </div>


        </div>

      </div>
    </section>
  );
}

export default SobrZuc;