import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import fed1 from '../../assets/FEED 1 (2).png'
import fed2 from '../../assets/FEED 1.1 (1).png'
import fed3 from '../../assets/FEED 1.2 (1).png'
import fed4 from '../../assets/FEED 1.3 (1).png'
import fed5 from '../../assets/FEED 1.4.png'
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import { useScreen } from "../../providers/ScreenSize";
import { AutoPlay } from "@egjs/flicking-plugins";



function CarrouselShow(props) {
    const plugins = [new AutoPlay({ duration: 3000, direction: "NEXT", stopOnHover: true })];
    const feeds = [fed1, fed2, fed3, fed4, fed5]




    return (

        <Flicking
            className="horizontal-carousel"
            circular={true}
            autoplay={true}
            duration={700}
            horizontal={true}
            vertical={false}
            plugins={plugins}// Set the carousel to vertical
        >
            {feeds.map((post, index) => {

                return (
                    <div key={index} className="carousel-panel">
                        {post.media_type === 'VIDEO' ? (
                            <video className="carrouselVideo" autoPlay muted webkit-playsinline playsinline >
                                <source src={post} type="video/mp4" />
                            </video>


                        ) : (<img src={post} alt={post.text} />
                        )

                        }
                    </div>
                )
            })}
        </Flicking>
    );
}

export default CarrouselShow;
