

function SobreTake() {
  return (
    <section className="welcome-container" id='sobreTake'>
      <div className="signature-container" id='sobreTake-container'>
        <div className="mao-celular-container">

        </div>
        <div className="signature-content">
          <div className="signature-text">
            <div className="signature-title" >

              <h1 id='blue'>O que é a Take?</h1>
            </div>
            <span>
              Com inteligência artificial embarcada, os coolers da Take oferecem uma<b> experiência de compra prática, inovadora e segura.</b>
            </span>
            <span>
              Substituindo o sistema de honestidade das conveniências comuns, a Take traz a segurança e tranquilidade de um sistema com  <b>apenas 1% de inadimplência.</b> O uso é intermediado por um aplicativo, onde é realizado um cadastro parecido com o dos bancos digitais e o sistema de pagamento é inteligente
            </span>
            <span> <b>Traduzindo, o usuário não indica o que está comprando, nem a quantidade. Esse trabalho é exclusivo da IA embarcada nas câmeras internas da geladeira, que fará a cobrança automática na forma de pagamento cadastrada no aplicativo intermediador.</b></span>
            <span> O melhor: é<b> gratuito para o condomínio!</b></span>

            <div className="signature-button-container">
              <button onClick={() => window.location.href = 'https://wa.me/5561998867848?text=Ol%C3%A1!%20Vi%20o%20site%20e%20gostaria%20de%20ter%20uma%20Take%20no%20%20meu%20estabelecimento'}>Saiba mais</button>

            </div>

          </div>
        </div>

      </div>
    </section>
  );
}

export default SobreTake;